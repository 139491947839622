jQuery(document).ready(function ($) {
	// Helper function for displaying messages
	function displayMessage(el, text) {
		el.html(text).slideDown();

		setTimeout(() => {
			el.slideUp().html('');
		}, 3000);
	}

});

