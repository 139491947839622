jQuery(document).ready(function ($) {
	"use strict";

	/**
	 * Toggle main menu
	 */
	$('.icon-burger').on('click', function () {
		$('body').toggleClass('is-menu-open');
	});

	$('.main-menu li:not(.menu-item-has-children) > a').on('click', function () {
		$('body').removeClass('is-menu-open');
	});

	// autoclose mobile menu when increasing window width
	function closeMobileMenu() {
		if ($('body').hasClass('is-menu-open')) {
			if ($(window).width() > 1199) {
				$('.icon-burger').trigger('click');
			}
		}
	}

	$(window).on('resize', function (e) {
		closeMobileMenu();
	});

	/**
	 * Header scrolled
	 */
	function checkScrollPosition() {
		let st = $(window).scrollTop();
		const header = $('.header');

		if ($('body').hasClass('is-menu-open')) {
			return;
		}

		if (st > 70) {
			$('body').addClass('is-scrolled');
		} else {
			$('body').removeClass('is-scrolled');
		}
	}

	$(window).on('load scroll', function (e) {
		checkScrollPosition();
	});

	/**
	 * Header search
	 */
	$('.header__search-icon, .header__search-overlay').on('click', function (e) {
		e.preventDefault();
		$('.header').toggleClass('is-search-active');
	});

	/**
	 * Tweak for mobiles
	 */
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);

	window.addEventListener('resize', () => {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	});


	/**
	 * Slider: Featured posts
	 */
	// initialize slider
	const sliderFeatured = new Swiper(".slider-featured", {
			loop: true,
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			},
			pagination: {
				el: ".slider-featured .swiper-pagination",
				clickable: true,
			},
			on: {
				slideChange: function () {
					try {
						lazyLoadInstance.update();
					} catch (e) {
					}
				}
			}
		});

	// pause slider on hover
	$('.slider-featured').hover(function () {
		sliderFeatured.autoplay.stop();
	}, function () {
		sliderFeatured.autoplay.start();
	});


	/**
	 * Mobile only slider
	 */
	const sliderMobileOnlyEl = $('.m-slider-mobile-only');
	if (sliderMobileOnlyEl.length) {
		sliderMobileOnlyEl.each(function () {
			const sliderEl = $(this)[0];

			let swiperMobileOnly;
			const enableSwiper = function () {
				swiperMobileOnly = new Swiper(sliderEl, {
					slidesPerView: 'auto',
					slidesPerGroup: 1,
					spaceBetween: 24,
					pagination: {
						el: sliderEl.querySelector('.swiper-pagination'),
						clickable: true,
					},
					breakpoints: {
						768: {
							spaceBetween: 30,
						}
					},
				});
			};

			$(window).on('load resize', function () {
				// if larger viewport
				if ($(window).width() > 991) {
					if (swiperMobileOnly !== undefined) swiperMobileOnly.destroy(true, true);
					return;
					// else if a small viewport
				} else {
					// fire small viewport version of swiper
					return enableSwiper();
				}
			});
		});
	}

	/**
	 * Equal Height calculation
	 */
	function setEqualHeight(container, element){
		// Select and loop the container element of the elements you want to equalise
		$(container).each(function(){

			// Cache the highest
			var highestBox = 0;

			// Select and loop the elements you want to equalise
			$(element, this).each(function(){

				// If this box is higher than the cached highest then store it
				if($(this).height() > highestBox) {
					highestBox = $(this).height();
				}

			});

			// Set the height of all those children to whichever was highest
			$(element,this).height(highestBox);

		});
	}

	$(window).on('load resize', function () {
		setEqualHeight('.m-products', '.article__title');
	});

	/**
	 * Nice select
	 */
	$('select').niceSelect();

});

