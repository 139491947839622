jQuery(document).ready(function ($) {

	/**
	 * Accordion
	 */
	$('.js-accordion-title').on('click', function () {
		const accordion = $(this).closest('.js-accordion'),
			accordionItem = $(this).closest('.js-accordion-item');
		if (accordionItem.hasClass('is-open')) {
			accordionItem.removeClass('is-open');
			accordionItem.find('.js-accordion-content').slideUp(300);
		} else {
			accordion.find('.js-accordion-item').removeClass('is-open');
			accordion.find('.js-accordion-content').slideUp(300);
			accordionItem.addClass('is-open');
			accordionItem.find('.js-accordion-content').slideDown(300);
		}
	});

	/**
	 * Tabs
	 */
	$('.js-tab-title').on('click', function () {
		const tabs = $(this).closest('.js-tabs');
		const item = $(this).data('item');
		if ('' !== item) {
			tabs.find('.js-tab-title').removeClass('active');
			$(this).addClass('active');
			tabs.find('.js-tab-content').removeClass('active');
			tabs.find('#' + item).addClass('active');
		}
	});

	/**
	 * Modal
	 */
	$('.js-modal-open').on('click', function (e) {
		e.preventDefault();
		const modal_id = $(this).data('modal');
		$('body').removeClass('is-menu-open').addClass('overflow-hidden');
		$('#' + modal_id).addClass('is-open');
	});

	$('.js-modal-close, .modal__overlay').on('click', function () {
		$('.modal').removeClass('is-open');
		$('body').removeClass('overflow-hidden');
	});

	/**
	 * Toggle
	 */
	$('.js-toggle').on('click', function (e) {
		e.preventDefault();
		const item = $(this).data('item');
		if ('' !== item) {
			$(this).toggleClass('is-open');
			$('#' + item).slideToggle();
		}
	});

	/**
	 * Smooth scroll to anchor
	 */
	$('a[href*="#"]:not([href="#"])').on('click', function () {
		if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
			if (target.length) {
				$('html,body').animate({
					scrollTop: target.offset().top
				}, 700);

				// remove anchor from url
				var hash = location.hash.replace('#', '');
				if (hash != '') {
					location.hash = '';
				}

				return false;
			}
		}

	});

});
